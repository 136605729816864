.header {
  padding: 0 0.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.trigger {
  display: none;
}

.btn-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

@media (max-width: 991px) {
  .header {
    justify-content: space-between;
  }

  .trigger {
    display: block;
  }
}
