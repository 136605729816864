.sidebarTitle {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2.3px;
  margin: 0;
}

@media (max-width: 991px) {
  .sidebarTitle {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .sidebarTitle {
    font-size: 20px;
  }
}

@media (max-width: 440px) {
  .sidebarTitle {
    font-size: 18px;
  }
}

