.center {
  align-items: center;
}

.width-100 {
  width: 100%;
}

.mb-0 {
  margin-bottom: 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gap-1 {
  gap: 1rem;
}

.reportTable thead > tr:first-of-type > th:not(:first-of-type) {
  font-weight: 900;
}

@media (max-width: 991px) {
  .mb-1 {
    margin-bottom: 8px;
  }

  .mb-2 {
    margin-bottom: 16px;
  }
}

@media (max-width: 541px) {
  .display-block {
    display: block;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .flex-end {
    flex-direction: column;
  }

  .mt-1 {
    margin-top: 18px;
    gap: 10px;
  }
}
