.outerDiv {
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  position: relative;
  width: 100%;
  height: 100%;
}

.link {
  font-size: 16px;
}

@media (max-width: 767px) {
  .outerDiv {
    font-size: 32px;
  }
}

@media (max-width: 541px) {
  .outerDiv {
    font-size: 24px;
  }
}
