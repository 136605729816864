.tableTitle {
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
}

.formTitle {
  text-align: center;
}

.formHolder {
  width: 100%;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 12px;
  margin: 0 auto;
  & h1 {
    text-align: center;
  }
}

.ant-layout-header {
  padding: 0 1rem !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}
