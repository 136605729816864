.loginPage {
  background-color: #e5e5e5;
  width: 100vw;
  height: 100vh;
  position: relative;
}
.formHolder {
  width: 400px;
  border-radius: 6px;
  padding: 16px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  background-color: white;

  & h2 {
    text-align: center;
  }
}
@media (max-width: 440px) {
  .formHolder {
    width: 320px;
  }
}

@media (max-width: 390px) {
  .formHolder {
    width: 220px;
  }
}
