.errorBoundary {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  height: 100vh;
  text-align: center;

  code {
    color: red;
    font-weight: normal;
    font-size: 14px;
  }
}
